@import "global.scss";
.clearableSelect {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  &>*:first-child {
    margin-top: 0;
  }

  &>*:last-child {
    margin-left: .5em;
    //background-color: red;
    margin-top: .4em;
  }
}
//
//.horizontalInputs {
//  display: flex;
//  flex-direction: row;
//
//  & > * {
//    width: 100%;
//    margin: 0 1em 0 0;
//
//    &:last-child {
//      margin-right: 0;
//    }
//  }
//}
