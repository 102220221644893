@import "global.scss";
.hourCheckbox {
  padding: 0 !important;

  .checked {
    width: 22px;
    height: 22px;
    background-color: #2cc158;
  }

  .unchecked {
    width: 22px;
    height: 22px;
    background-color: #dddddd;
  }
}
