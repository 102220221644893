@import "global.scss";
.privacy {
  width: 90%;
  min-width: 420px;
  max-width: 900px;
  margin: 2em auto;
  padding: 4em 0 8em 0;

  img {
    display: block;
    width: 50%;
    height: auto;
    margin: 4em auto;
  }

  h1 {
    font-weight: 700;
    font-size: 2em;
    margin: 0 0 1em 0;
  }

  h2 {
    font-weight: 700;
    font-size: 1.6em;
    margin: 1.5em 0 1em 0;
  }

  h3 {
    font-weight: 500;
    font-size: 1.2em;
    margin: 1em 0 .5em 0;
  }

  ul {
    list-style-type: square;
  }
}
