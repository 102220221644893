$uob-red: #b01c2e;
$uob-red-alt: #af1e2d;
$uob-white: #ffffff;
$uob-black: #000000;
$uob-cool-grey: #e5e6e5;

$primary: #1565c0;

$text-primary-darker: #444444;
$text-primary: #666666;
$text-primary-lighter: #888888;

$highlight: #eee;

$border: 1px solid #ccc;
$border-radius: .25em;
