@import "global.scss";
.customValidationGroup{
  .label {
    margin-bottom: .5em;
  }

  .helperText {
    margin-top: 1em;
  }
}
