@import "global.scss";
.inviteResearcher {
  width: 30em;
  margin: 2em auto;
  padding: 2em;
  border: $border;
  border-radius: $border-radius;

  .description {
    margin-bottom: 1em;

    a {
      color: $primary;
      font-weight: 500;
      text-decoration: underline;
    }
  }

  .horizontalInputs {
    display: flex;
    flex-direction: row;

    & > * {
      width: 100%;
      margin: 0 1em 0 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .formControls {
    margin-top: 1em;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .alert {
    margin-top: 1em;
  }
}
