@import "global.scss";
.horizontalInputs {
  display: flex;
  flex-direction: row;
  width: 100%;

  & > * {
    flex-grow: 1;
    margin-top: 0;
  }
}

.question {
  margin: 1em 0;
  border: $border;
  border-radius: $border-radius;
  box-sizing: border-box;

  .hiddenInput {
    display: none;
  }

  .topRow {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    background-color: #f6f6f6;
    border-bottom: $border;

    .topField {
      width: 15em;
      margin-right: 1em;
      min-width: 15em;

      &:first-child {
        width: 100%;
        margin-right: 2em;
      }
    }

    .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      align-self: center;
      width: 6em;
      min-width: 6em;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 0 1em;

    &.extraPadding {
      padding-top: 1em;
    }

    & > * {
      margin-top: 0;
    }

    .field {
      width: 26em;
    }

    .numberConstraints {
      width: 100%;
    }

    .fullWidthField {
      width: 100%;
    }

    .skipTopMargin {
      & > * {
        margin-top: 0;
      }
    }

    .separator {
      width: 1em;
      flex-grow: 0;
    }

    .arrowSeparator {
      width: 6em;
      display: flex;
      margin-top: -1.43em;
      align-self: center;
      align-items: center;
      justify-content: center;
      color: $text-primary-lighter;
    }
  }

  .answers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .answersValidator{
    width: 100%;
    box-sizing: border-box;
    padding: 0 1em .5em 1em;

    & > * {
      margin-top: 0;
    }
  }

  .addAnswer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 1em 0;

    button {
      margin-left: 2.5em;
    }
  }
}

