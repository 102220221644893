@import "global.scss";
.hourMatrix {
  margin: 1em 0;

  .label {
    margin-bottom: 1em;
  }

  .helperText {
    margin-top: 1em;
  }

  table {
    margin: 0 auto;
    border-spacing: 1px;

    thead {
      tr {
        th {
          text-align: center;
          padding: 0 .5em;

          &.hour {
            padding: 0;
            min-width: 20px;
            max-width: 20px;
          }
        }
      }
    }

    tbody {
      tr {
        th {
          padding: 0;
          text-align: right;
          height: 20px;
          max-height: 20px;

          &.centered {
            text-align: center;
          }

          button {
            margin: 0 .5em;
          }
        }

        td {
          padding: 0 1px;
          height: 20px;
          width: 20px;
          max-width: 20px;
          max-height: 20px;
          text-align: center;
        }
      }
    }
  }

  .showPreviewButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2em 0;
  }

}
