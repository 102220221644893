@import "global.scss";
.notificationPrompt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 2em;
  padding: 1.25em;
  //border-radius: $border-radius;
  border: $border;
  //box-shadow: 0 0 .35em #ccc;
  color: $text-primary;

  &:last-child {
    margin-bottom: 0;
  }

  .topRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: .75em;
    color: $text-primary-lighter;

    .icon {
      color: $text-primary-lighter;
      margin-right: .5em;
    }

    .spacer {
      flex-grow: 1;
    }

    h1 {
      font-size: 1.05em;
      font-weight: 400;
      margin-right: 1em;
    }

    p {
      color: $text-primary-lighter;
      font-weight: 400;
    }
  }

  .contentRow {
    box-sizing: border-box;

    h2 {
      font-weight: 600;
      font-size: 1.1em;
      margin-bottom: .75em;
    }

    p {
      font-weight: 400;
    }
  }
}
