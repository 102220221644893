@import "global.scss";
.studyResults {
  width: 40em;
  padding: 2em;
  margin: 0 auto;
  border: $border;
  border-radius: $border-radius;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;

    h1 {
      max-width: 80%;
      font-size: 1.5em;
      margin-right: 1em;
      color: $text-primary-darker;
    }

    p {
      margin: 1em 0;
      color: $text-primary-darker;
    }
  }

  .formControls {
    margin-top: 1em;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .alert {
    margin-top: 1em;
  }
}
