@import "global.scss";
.answer {
  width: 100%;
  margin: 1em 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    td {
      padding-top: 1.25em;
      vertical-align: top;
    }

    tr:first-child td {
      padding-top: .25em;
    }

    tr:first-child td.icon {
      padding-top: 0;
    }

    .icon {
      padding-top: 1em;
      width: 5%;
      color: $text-primary-lighter;
    }

    .value {
      width: 40%;
      margin-left: 1em;
      margin-right: 1em;
    }

    .nextQuestion {
      width: 40%;
      text-decoration: underline;
    }

    .questionnaireEnds {
      width: 45%;
      font-style: italic;
    }
  }
}

