@import "global.scss";
.support {
  width: 90%;
  min-width: 420px;
  max-width: 900px;
  margin: 2em auto;
  padding: 4em 0 8em 0;
  text-align: center;

  img {
    display: block;
    width: 50%;
    height: auto;
    margin: 4em auto;
  }

  h1 {
    font-weight: 700;
    font-size: 2em;
    margin: 0 0 1em 0;
  }

  p {
    font-size: 1.2em;
  }

  a {
    color: #1565c0;
  }
}
