@import "global.scss";
.promptsForm {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.prompts {
  margin: 1em 0;

  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
}

.addPrompt {
  width: 100%;
  margin: 1em 0 2em 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

