@import "global.scss";
.study {
  width: 60em;
  padding: 2em;
  margin: 0 auto;
  border: $border;
  border-radius: $border-radius;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;

    h1 {
      max-width: 80%;
      font-size: 1.5em;
      margin-right: 1em;
      color: $text-primary-darker;
    }
  }

  .dates {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
    color: $text-primary-lighter;

    .date {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      &.endDate {
        align-items: flex-end;
      }

      .dateHeader {
        margin-bottom: .5em;
      }
    }
  }

  .description {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1em;
    color: $text-primary-darker;
  }

  .stages {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .stageRow {
      //width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 1em 0;

      .stage {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }

      .stageSeparator {
        color: $text-primary-lighter;
        margin: .35em;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }


  .tiles {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .tile {
      width: 30%;
      padding: 1em;
      margin: 1em;
      box-sizing: border-box;
      border-radius: $border-radius;
      border: $border;

      h3 {
        color: $text-primary-darker;
        border-bottom: $border;
        text-align: center;
        margin-bottom: 1em;
        padding: 0 1em 1em 1em;
      }

      p {
        font-size: 1.3em;
        text-align: center;
        color: $text-primary-darker;
      }
    }

    a.tile{
      cursor: pointer;

      &:hover,
      &:active {
        box-shadow: 0 0 .5em $highlight;

        h3, p {
          color: $primary;
        }
      }
    }
  }
}
