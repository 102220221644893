@import "global.scss";
.answer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: -1em ;

  .hiddenInput {
    display: none;
  }

  .arrowSeparator {
    width: 6em;
    display: flex;
    margin-top: -1.43em;
    align-self: center;
    align-items: center;
    justify-content: center;
    color: $text-primary-lighter;
  }

  .radioIcon {
    color: $text-primary-lighter;
    width: 1.5em;
    padding: 0 1em;
    align-self: center;
    margin-top: -1.43em;
  }

  .optionField {
    width: 23.5em;
  }

  .nextQuestionField {
    width: 19em;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-self: center;
    width: 6em;
    padding-left: 1em;
    padding-right: 1em;
    margin-top: -1.43em;
  }
}

