@import "global.scss";
.wrapper {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: row;
}

.leftColumn {
  min-height: 100%;
}

.rightColumn {
  min-height: 100%;
  max-height: 100%;
  //overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
}

.view {
  width: 90%;
  max-width: 1200px;
  margin: 3em auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
