@import "global.scss";
.question {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 2em;
  border: $border;
  border-radius: $border-radius;
  color: $text-primary;

  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: $border;
    padding: 1em;
    box-sizing: border-box;
    background-color: #f6f6f6;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;


    h1 {
      display: block;
      font-weight: 500;
      font-size: 1.1em;
    }

    p {
      display: block;
    }
  }

  .content {
    width: 100%;
    padding: .5em 1.25em;
    box-sizing: border-box;

    .parameters {
      width: 100%;

      table {
        width: 100%;
        border-collapse: collapse;

        tbody {
          tr {
            border-bottom: $border;

            &:last-child {
              border-bottom: none;
            }
          }

          td {
            width: 50%;
            padding: .5em 1em .5em 0;
            //border-right: $border;

            &:first-child {
              //border-left: $border;
              font-style: italic;
            }
          }
        }
      }
    }

    .answers {
      margin: 1em 0;
    }
  }

  .footer {
    width: 100%;
    padding: .5em 1em;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-top: $border;

    h2 {
      font-weight: 500;
      margin-right: 1em;
      min-width: 7em;
    }

    p {
      margin-right: 1em;
      text-decoration: underline;
    }

    .icon {
      margin-right: 1em;
      color: $text-primary-lighter
    }

    .questionnaireEnds {
      font-style: italic;
      text-decoration: none;
    }
  }
}
