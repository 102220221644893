@import "global.scss";
.horizontalInputs {
  display: flex;
  flex-direction: row;

  & > * {
    width: 100%;
    margin-right: 1em;

    &:last-child {
      margin-right: 0;
    }
  }
}

.activeHoursFormGroup {
  min-height: 16em;

  .activeHoursTextInput {
    //margin-bottom: 0;
  }
}

.selectedRangeHours {
  margin: 2.5em 0 1em 0;
}

.dailyFrequencyRange {
  margin: 1em 0;
}

.daysOfTheWeek {
  margin: 1em 0;
}
