@import "global.scss";
.createSchedule {
  width: 50em;
  margin: 2em auto;
  padding: 2em;
  border: $border;
  border-radius: $border-radius;

  .description {
    margin-bottom: 1em;

    a {
      color: $primary;
      font-weight: 500;
      text-decoration: underline;
    }
  }
}

.alert {
  margin-top: 1em;
}
