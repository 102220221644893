@import "global.scss";
.navbar {
  background: $uob_white;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  border-right: $border;
}

.itemsWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 1em 0;
}

.fillMax {
  width: 100%;
  flex-grow: 1;
}
