@import "global.scss";
.schedule {
  width: 60em;
  margin: 2em auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .topRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    h1 {
      font-size: 1.5em;
      font-weight: 500;
      color: $text-primary;
      margin-bottom: .5em;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & > * {
        margin-left: 1em;
      }
    }
  }

  .tiles {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .tile {
      width: 30%;
      padding: 1em;
      margin: 1em;
      box-sizing: border-box;
      border-radius: $border-radius;
      border: $border;

      h3 {
        color: $text-primary-darker;
        border-bottom: $border;
        text-align: center;
        margin-bottom: 1em;
        padding: 0 1em 1em 1em;
      }

      p {
        font-size: 1.3em;
        text-align: center;
        color: $text-primary-darker;
      }
    }

    a.tile{
      cursor: pointer;

      &:hover,
      &:active {
        box-shadow: 0 0 .5em $highlight;

        h3, p {
          color: $primary;
        }
      }
    }
  }

  p {
    font-size: 1.1em;
    color: $text-primary-darker;
  }

  .preview {
    margin: 2em 0;
    width: 100%;
  }
}
