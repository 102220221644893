@import "global.scss";
.horizontalInputs {
  display: flex;
  flex-direction: row;

  & > * {
    width: 100%;
    margin: 0 1em 0 0;

    &:last-child {
      margin-right: 0;
    }
  }
}

.questionnaireMeta {
  margin-bottom: 2em;
}

.questions {
  display: flex;
  flex-direction: column;
}

.addQuestion {
  width: 100%;
  margin: 1em 0 2em 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
