@import "global.scss";
.signIn {
  width: 20em;
  margin: 2em auto;
  padding: 2em;
  border: $border;
  border-radius: $border-radius;

  form {
    margin-top: 1em;
  }

  .description {
    margin-bottom: 1em;

    a {
      color: $primary;
      font-weight: 500;
      text-decoration: underline;
    }
  }

  .formControls {
    margin: 1em 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .alert {
    margin-top: 1em;
  }
}
