@import "global.scss";
.table {

  // Linked column values (i.e. name column linking to /entities/${id}) via rowLinks mapping
  .link {
    text-decoration: underline;
  }

  .linkIcon {
    color: $text-primary;
  }
}
