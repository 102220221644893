@import "global.scss";
.questionnaireInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .topRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    h1 {
      font-size: 1.5em;
      font-weight: 500;
      color: $text-primary;
      margin-bottom: .5em;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & > * {
        margin-left: 1em;
      }
    }
  }

  .description {
    margin-bottom: 1em;
  }

  .questionnaireOption {
    padding-left: 1em;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: .75em;
    font-family: "Roboto","Helvetica","Arial",sans-serif;

    a {
      text-decoration: underline;
    }

    .icon {
      margin-right: .5em;
      color: $text-primary-lighter;
      position: relative;
      bottom: .2em;
    }

    .option {
      font-weight: 300;
      margin-right: 1em;
      color: $text-primary-darker;
    }

    .value {
      font-weight: 600;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      color: $text-primary-lighter;
    }
  }

  p {
    font-size: 1.1em;
    color: $text-primary-darker;
  }
}

.sectionHeader {
  margin: .5em 0 1em 0;
  font-size: 1.35em;
  font-weight: 500;
  color: $text-primary-lighter;
}

.notificationPrompts {
  width: 100%;
  margin: 2em 0;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: .5em 1em;
    box-sizing: border-box;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    background-color: #f6f6f6;
    border-bottom: $border;

    p {
      color: $text-primary;
      font-size: 1.1em;
      font-weight: 400;
    }
  }
}

.questions {
  width: 100%;
  margin: 2em 0;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: .5em 1em;
    box-sizing: border-box;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    background-color: #f6f6f6;
    border-bottom: $border;

    p {
      color: $text-primary;
      font-size: 1.1em;
      font-weight: 400;
    }
  }
}
