@import "global.scss";
.prompt {
  width: 100%;
  margin: 1em 0;
  display: flex;
  flex-direction: column;
  border: $border;
  border-radius: $border-radius;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: .5em 1em;
    box-sizing: border-box;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    background-color: #f6f6f6;
    border-bottom: $border;

    p {
      color: $text-primary;
      font-size: 1.1em;
      font-weight: 400;
    }
  }

  .fields {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 0 1em;
    box-sizing: border-box;

    & > * {
      width: 100%;

      &:first-child {
        margin-right: .5em;
      }

      &:last-child {
        margin-left: .5em;
      }
    }
  }

  & > * {
    width: 100%;
    margin: 0;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 2.5em;
    margin-left: 1em;
  }
}
